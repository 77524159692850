/* * * * * * * * * */
/* Base URL        */
/* * * * * * * * * */

function base_url() {
  pathArray = window.location.href.split( '/' );
  protocol = pathArray[0];
  host = pathArray[2];
  url = protocol + '//' + host;

  return url;
}

var base_url = base_url();

/* * * * * * * * * */

/* * * * * * * * * */
/* Shido Grid      */
/* * * * * * * * * */

function fixSpace(){
  $('.line, ul').contents().filter(function(){
      return this.nodeType === 3;
  }).remove();
}

/* * * * * * * * * */

/* * * * * * * * * */
/* General         */
/* * * * * * * * * */

function content(){
  hSite = $('html').outerHeight();
  hFoo = $('footer').outerHeight();
  hSite = hSite - hFoo;
  $('.main-container').css('min-height', hSite+'px');
  return hSite;
}

/* * * * * * * * * */
